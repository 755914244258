<template>
  <div>
    <template
      v-for="(component, index) in components"
      :key="`component_${index}`"
    >
      <!-- <component
        :is="getComponent(component.name, component.type.identifier)"
        :data="component.content"
        :block-data="component?.block_type_content"
        :name="component.type.identifier"
        :block-name="component.name"
        :plp-type="plpType"
        :block-index="index"
        @onPageUpdate="$emit('onPageUpdate', $event)"
      /> -->
      <component
        :is="getComponent(component.name, component.type.identifier)"
        v-if="component.currentTranslation?.content"
        :data="component.currentTranslation.content"
        :block-data="component?.block_type_content"
        :name="component.type.identifier"
        :block-name="component.name"
        :plp-type="plpType"
        :block-index="index"
        :page-data="pageData"
        @onPageUpdate="$emit('onPageUpdate', $event)"
      />
    </template>
  </div>
</template>

<script lang="ts" setup>
import { BlockData, AllBlockContents } from "@/types/api/block.types";
import { PageData } from "@/types/api/pages.types";
import { plpTypes } from "@/configs";

const { getComponent } = useBlockContainer();

// // const BlockAbout = resolveComponent("BlocksBlockAbout");
// const BlocksBlockProductList = resolveComponent("BlocksBlockProductList");
// const BlocksBlockPlpTopBanner = resolveComponent("BlocksBlockPlpTopBanner");
// // const BlockHeadImageSwiper = resolveComponent("PagesHomeBlockHeadImageSwiper");
// const HomeNowTrendingSlider = resolveComponent("PagesHomeBlockNowTrendingNow");
// // const HomeBlockHomeSlides = resolveComponent("PagesHomeBlockHomeSlides");
// // const BlocksBlockDiamondPlp = resolveComponent("BlocksBlockDiamondPlp");
// // const BlockHomeAlwaysEngaging = resolveComponent(
// //   "PagesHomeBlockHomeAlwaysEngaging",
// // );
// const BlocksBlockProperties = resolveComponent("BlocksBlockProperties");
// // const BlockHomeMediaSlider = resolveComponent("PagesHomeBlockHomeMediaSlider");
// // const BlockHomeLabGrownDiamonds = resolveComponent(
// //   "PagesHomeBlockHomeLabGrownDiamonds",
// // );
// // const BlockHomeYouPremierFineSlider = resolveComponent(
// //   "PagesHomeBlockHomeYouPremierFineSlider",
// // );
// const BlockRecentlyView = resolveComponent("PagesHomeBlockRecentlyView");
//
// const BlockAccordion = resolveComponent("BlocksBlockAccordion");
// const BlockArticles = resolveComponent("BlocksBlockArticles");
// const BlockBestseller = resolveComponent("BlocksBlockBestseller");
// const BlockComparison = resolveComponent("BlocksBlockComparison");
// const BlockInstagram = resolveComponent("BlocksBlockInstagram");
// const MarketingCollections = resolveComponent("BlocksMarketingCollections");
// const BlockMedia = resolveComponent("BlocksBlockMedia");
// const BlockPromoted = resolveComponent("BlocksBlockPromoted");
// const BlockRichText = resolveComponent("BlocksBlockRichText");
// const BlockSale = resolveComponent("BlocksBlockSale");
// const BlockWishlist = resolveComponent("BlocksBlockWishlist");
// const BlockCategoriesDefault = resolveComponent("BlocksBlockCategoriesDefault");
// const BlockDefaultSlider = resolveComponent("BlocksBlockDefaultSlider");
// const BlockTopSlider = resolveComponent("BlocksBlockTopSlider");
// const BlockContactUs = resolveComponent("PagesContactUsBlockContactUs");
// // const BlockEntityGrid = resolveComponent("BlocksBlockEntityGrid");
// // const Block3Cols = resolveComponent("BlocksBlock3Cols");
// // const BlockHomeLabDiamondsTopSlider3Cols = resolveComponent(
// //   "BlocksBlockHomeLabDiamondsTopSlider",
// // );
// // const BlockProductListEngagement = resolveComponent(
// //   "BlocksBlockProductListEngagement",
// // );
//
// const CollectionCustomSlider = resolveComponent(
//   "BlocksCustomBlocksHomeCollections",
// );
// const MapBlock = resolveComponent("BlocksCustomBlocksMapBlock");
// const BlocksCustomBlocksFormContactUs = resolveComponent(
//   "BlocksCustomBlocksFormContactUs",
// );
// const BlockPlpEntityFilter = resolveComponent("BlocksBlockPlpEntityFilter");
//
// const HomeBreakFree = resolveComponent("BlocksCustomBlocksHomeBreakFree");
// const HomeDiscoverDiamondNexus = resolveComponent(
//   "BlocksCustomBlocksHomeDiscoverDiamondNexus",
// );
//
// interface ComponentsInterface {
//   [key: string]: ConcreteComponent | string;
// }
//
// const defaultComponentOptions: ComponentsInterface = {
//   catalog: BlocksBlockProductList,
//   accordion: BlockAccordion,
//   articles: BlockArticles,
//   bestseller: BlockBestseller,
//   categories: BlockCategoriesDefault,
//   comparison: BlockComparison,
//   // entity: BlockEntities,
//   entity: BlocksBlockProperties,
//   // entity: BlockShopByDiamondShape,
//   instagram: BlockInstagram,
//   "marketing-collections": MarketingCollections,
//   media: BlockMedia,
//   promoted: BlockPromoted,
//   "recently-viewed": BlockRecentlyView,
//   "rich-text-module": BlockRichText,
//   sale: BlockSale,
//   slider: BlockDefaultSlider,
//   "slider-2-columns": HomeNowTrendingSlider,
//   wishlist: BlockWishlist,
//   "top-slider": BlockTopSlider,
//   // entity: BlocksBlockCategories, // TODO figure out why it send error
//   "plp-top-banner": BlocksBlockPlpTopBanner,
// };
//
// const customComponentsOptions: ComponentsInterface = {
//   // "fine-jewelry-about": BlockAbout,
//   // "plp-page-top-banner": BlocksBlockPlpTopBanner, // TODO remove
//   // "home-header-slider": BlockHeadImageSwiper,
//   // "home-slides": HomeBlockHomeSlides, // TODO remove
//   // "diamonds-settings-plp": BlocksBlockDiamondPlp,
//   // "home-always-engaging-slider": BlockHomeAlwaysEngaging, // TODO change on home page
//   // "home-media-slider": BlockHomeMediaSlider, // TODO remove
//   // "home-lab-grown-diamonds": BlockHomeLabGrownDiamonds, // TODO change on home page
//   // "home-your-premier-fine-slider": BlockHomeYouPremierFineSlider, // TODO change on home page
//   "contact-us-block": BlockContactUs,
//   // "entity-grid": BlockEntityGrid,
//   // "fine-jewelry-two-slider": Block3Cols,
//   // "home-lab-diamonds-top-slider": BlockHomeLabDiamondsTopSlider3Cols,
//   // "engagement-ring-plp": BlockProductListEngagement,
//
//   "collection-custom-slider": CollectionCustomSlider,
//   "location-map": MapBlock,
//   "form-contact-us": BlocksCustomBlocksFormContactUs,
//   "plp-shop-by": BlockPlpEntityFilter,
//   "home-break-free": HomeBreakFree,
//   "home-discover-diamond-nexus": HomeDiscoverDiamondNexus,
// };
//
// function getComponent(
//   name: string,
//   identifier: string,
// ): ConcreteComponent | string {
//   if (name.includes("plp-shop-by-")) {
//     name = "plp-shop-by";
//   }
//   return (
//     customComponentsOptions[name] || defaultComponentOptions[identifier] || ""
//   );
// }

withDefaults(
  defineProps<{
    components: BlockData<AllBlockContents>[];
    plpType?: string;
    pageData: PageData | null;
  }>(),
  {
    components: () => [],
    plpType: plpTypes.default,
    pageData: null,
  },
);

const { scrollDepthEvent } = useEvents();

const { observerOnMounted, observerOnBeforeUnmount } =
  intersectionObserverInit(scrollDepthEvent);

onMounted(() => {
  observerOnMounted();
});

onBeforeUnmount(() => {
  observerOnBeforeUnmount();
});
</script>
